<template>
  <v-card
    :style="`border-left: 6px solid ${color}`"
    class="rounded-lg anys-card"
    flat
    height="70"
    :width="$vuetify.breakpoint.xs ? '100%' : 160"
    @click="url()"
  >
    <div class="px-4 py-2 d-flex flex-column rounded-l-0"
         style="height: 100% !important; width: 100% !important; border: thin solid rgba(217, 220, 231, 0.7) !important; border-left: none !important;"
    >

        <span class="tit font-weight-bold blue-grey--text text--darken-3 two-lines-truncate mt-1"
              style="font-size: 16px !important; color: #434343 !important;"
        >
          {{ year }}
        </span>

      <v-spacer></v-spacer>

      <div class="d-flex">
          <span class="caption" style="color: rgba(32,32,32,0.7)">
            3 series
          </span>
        <v-spacer></v-spacer>
        <v-btn x-small icon tile>
          <v-icon size="22">{{mdiFilePdfBox}}</v-icon>
        </v-btn>
      </div>

    </div>

  </v-card>

</template>

<script>
import {mdiFilePdfBox} from '/src/assets/mdi.json'

export default {
  name: "CardYear2022",
  props: {
    year: {
      required: true
    },
    color: {
      type: String,
      required: true
    },
    subject_path: {
      type: String
    }
  },
  data() {
    return {
      mdiFilePdfBox: mdiFilePdfBox
    }
  },
  methods: {
    async url() {
      if (this.$vuetify.breakpoint.smAndDown) {
        const pdf = `https://examenselectivitat.cat:3000/api/pdfs/${this.subject_path}/${this.year}/Juny/Enunciat`;
        window.open(pdf);
      } else {
        this.$router.push({path: '/selectivitat/' + this.$route.params.subject_name + '/pdf/' + this.year + '/Juny/Enunciat'})
      }
    },
  }
}
</script>

<style scoped>
.anys-card {
  box-shadow: 0px 2px 4px rgba(188, 198, 212, 0.14), 0px 4px 5px rgba(188, 198, 212, 0.12), 0px 1px 10px rgba(188, 198, 212, 0.2) !important;
  cursor: pointer;
  width: 250px;
  border-radius: 5px !important;
}
</style>
