<template>
  <div style="padding-bottom: 30px !important; background-color: white" class="px-4">


    <div style="max-width: 1100px; position: relative"
         class="mx-auto"
    >


      <!-- ============================== -->
      <!--  Header                        -->
      <!-- ============================== -->
      <v-sheet class="pt-5 pb-8 rounded-b"
               :style="subject ? `background: linear-gradient(to bottom,rgba(255,255,255,0.6),rgba(255,255,255,0.6)),${getColor}` : 'background: rgb(251 251 251)'"
               style="position: relative !important;"
               ref="subjectHeader"
      >

        <div style="max-width: 1100px; margin: auto; position: relative !important;"
             :class="$vuetify.breakpoint.xs ? '' : 'px-8'">


          <div
            v-if="$refs.subjectHeader && subject"
            class="d-flex"
            style="position: absolute; left: 0px; top: -20px; width: 100%; max-height: 148px">
            <v-col v-for="col in ($vuetify.breakpoint.smAndDown ? 3 : 6)"
                   style="position: relative"
                   :cols="($vuetify.breakpoint.smAndDown ? 4 : 2)"
            >
              <v-icon
                :color="getColor"
                size="30"
                style="opacity: 0.4; position: absolute"
                :style="`transform: rotate(${Math.floor(Math.random() * 40)-20}deg) scale(${1 + Math.min(Math.random(), 4)}) !important; top: ${Math.floor(Math.random() * 100)}px; left: ${(Math.floor(Math.random() * (($refs.subjectHeader.$el.offsetWidth-120)/($vuetify.breakpoint.smAndDown ? 3 : 6))))}px`"
                class="icon-color"
              >
                {{ $vuetify.icons.values[subject.subject_info.subject_icon] }}
              </v-icon>
            </v-col>
          </div>


          <div :class="$vuetify.breakpoint.xs ? 'justify-center text-center' : 'd-flex'"
               style="width: 100%; position: relative; z-index: 10">


            <v-card
              v-if="subject"
              :height="$vuetify.breakpoint.smAndDown ? 60 : 80"
              :width="$vuetify.breakpoint.smAndDown ? 60 : 80"
              class="text-center d-flex mx-auto shadow-small mt-4 rounded-circle"
              style="z-index: 3"
              flat
              color="white"
            >
              <v-icon
                v-if="subject"
                :color="getColor"
                :size="$vuetify.breakpoint.smAndDown ? 30 : 40"
                style="margin: auto; opacity: 0.9"
                class="icon-color"
              >
                {{ $vuetify.icons.values[subject.subject_info.subject_icon] }}
              </v-icon>
            </v-card>

            <v-skeleton-loader
              v-else
              :height="$vuetify.breakpoint.smAndDown ? 60 : 80"
              :width="$vuetify.breakpoint.smAndDown ? 60 : 80"
              class="mx-auto mt-4"
              :style="$vuetify.breakpoint.smAndDown ? 'border-radius: 30px' : 'border-radius: 40px'"
              type="image"
            ></v-skeleton-loader>


            <div :class="$vuetify.breakpoint.xs ? 'justify-center text-center' : 'd-flex'"
                 class="align-start justify-start"
                 style="flex: 1; flex-direction: column">


              <div class="d-flex align-center mt-2"
                   :class="$vuetify.breakpoint.xs ? 'd-flex flex-column justify-center px-4 pt-3' : ''"
                   style="width: 100%">


                <h1 class="font-rubik font-weight-medium py-4 px-4 rounded-lg"
                    style="font-size: 36px; color: #1f1f1f;"
                >
                  {{ $route.params.subject_name }}
                </h1>

                <v-spacer></v-spacer>

                <div class="d-flex flex-column">
                  <v-btn
                    v-if="false"
                    depressed
                    color="primary"
                    class="text-none font-rubik py-4"
                    :class="$vuetify.breakpoint.xs ? 'mt-2' : ''"
                    style="letter-spacing: normal"
                  >
                    Seguir
                  </v-btn>

                </div>

              </div>

              <div
                class="px-4">
                <div
                  v-if="subject && subject.subject_info && subject.subject_info.subject_desc"
                  class="d-flex flex-column"
                >
                <span
                  class="font-rubik ml-0"
                  v-html="subject.subject_info.subject_desc.replace(/\n/g, '<br>')"
                  :class="showDesc ? '' : 'three-lines-truncate'"
                ></span>
                  <span
                    @click="showDesc=!showDesc"
                    class="text-decoration-underline blue--text ml-4"
                    style="cursor:pointer;"
                  >
                    <v-icon size="14"
                            class="text-decoration-underline"
                            color="blue">{{ showDesc ? mdiChevronUp : mdiChevronDown }}</v-icon>
                    Veure {{ showDesc ? 'menys' : 'més' }}
                  </span>
                  <br>
                  <br>
                </div>
                <span class="font-rubik ml-0">La font d'aquests documents prové de la Generalitat de Catalunya. Les solucions que es troben són les oficials.</span>
              </div>

              <div v-if="!$vuetify.breakpoint.xs" class="px-2 text-start align-start justify-start">
                <p
                  v-if="subject && subject.examsPerYear.length === 0"
                  style="color: rgba(32,32,32,0.7); font-size: 17px !important" class="mb-0">
                  Encara estem ordenant els temes {{ deApostrof }}... Si vols col·laborar amb a la comunitat, contacta
                  amb
                  nosaltres: contacte@examenselectivitat.cat
                </p>
              </div>


            </div>
          </div>

        </div>

      </v-sheet>


      <div v-if="hasExams"
           :key="refreshExams"
      >


        <div
          style="max-width: 1100px"
          class="mx-auto px-2 mt-6"
        >
          <examens-oficials
            class="mt-1 mx-auto"
            :subject_path="subject ? subject.subject_info.subject_path : ''"
            :color="getColor"
            :props-data="subject"></examens-oficials>
        </div>

        <!--        <h2 class="mb-3 mt-6 font-weight-medium font-rubik">Dossier {{deApostrof}}</h2>-->


        <booklet-card
          v-if="subject && subject.subject_info"
          :subject-name="$route.params.subject_name"
          :sInfo="subject.subject_info"
          class="my-6"
        ></booklet-card>


        <div
          style="max-width: 1100px"
          class="mx-auto px-2"
        >

          <h2
            id="temes"
            class="mb-3 mt-6 font-weight-medium font-rubik">Problemes classificats per temes</h2>

          <temes
            class="temes"
            :topics="subject && subject.examsPerTopic ? subject.examsPerTopic : []"
            :color="getColor"
          ></temes>


        </div>

      </div>

      <div
        v-else
        class="mx-auto" style="max-width: 1100px; "
      >
        <PDFCard class="mt-2"
                 style="box-shadow: 0 12px 24px rgb(2 30 84 / 10%) !important;"
        ></PDFCard>
      </div>


    </div>

  </div>
</template>

<script>
import VLoader from '../../../components/VLoader'
import PDFCard from './components/PDFTable'

import TextComponent from "../../../components/TextComponent";
import Subject from "./Subject";
import BookletCard from "../widgets/BookletCard";
import ExamensOficials from "./components/ExamensOficials";
import Temes from "./components/Temes";
import {mdiChevronDown, mdiChevronUp} from '/src/assets/mdi.json'

export default {
  metaInfo() {
    return {
      title: `${this.$route.params.subject_name}: Tots els exàmens de la selectivitat (2024)`,
      meta: [
        {charset: 'utf-8'},
        {
          name: 'description', content: `Tots els exàmens ${this.deApostrof} que han sortit fins ara a la Selectivitat.
         A més pots estudiar-los per temes, cada problema està classificat segons el seu tema.
          Comparteix els problemes via Whatsapp o troba la solució en un sol clic.`
        },
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  components: {
    Temes,
    ExamensOficials,
    BookletCard,
    TextComponent,
    VLoader,
    PDFCard,
  },
  data() {
    return {
      hasExams: true,
      loading: false,
      showDesc: false,
      subject: null,
      startPosition: null,
      refreshExams: 0,
      mdiChevronDown: mdiChevronDown,
      mdiChevronUp: mdiChevronUp,
    }
  },
  computed: {
    breadcrumb() {
      return [
        {
          text: 'Assignatures',
          disabled: false,
          to: '/',
        },
        {
          text: this.$route.params.subject_name,
          disabled: true,
          to: '',
        },
      ]
    },
    getColor() {
      const colors = [
        "#FF8FB1",
        "#256EFF",
        "#FFA201",
        "#2FCA9F",
        "#C47AFF",
        "#40C4FF",
        "#A460ED",
        "#8C9EFF",
        "#2F89FC",
        "#00BFA5",
        "#ffa63c",

      ]


      return this.subject ? colors[(this.subject.subject_info.subject_id) % colors.length] : ''
    },
    descripcio() {
      let text = "";
      if (this.subject) {
        text += `La millor forma d'estudiar pels exàmens de Batxillerat i per la Selectivitat  ${this.deApostrof}
        és practicant. És per això que en aquesta pàgina trobaràs tots exàmens de les PAU ordenats per temes. I per a cada problema, la seva respectiva solució oficial en un clic. `
        if (this.subject.examsPerTopic && this.subject.examsPerTopic.length > 0) {
          const link = `/selectivitat/${this.$route.params.subject_name}/per-temes/${this.subject.examsPerTopic[0].topic_path}`;
          text += `<br>A més, si d'aquí a poc tens un examen sobre "${this.subject.examsPerTopic[0].topic_name.toLowerCase()}"
          per exemple, a la secció sobre
            <a href="${link}">
            ${this.subject.examsPerTopic[0].topic_name}
            </a>
          tens una àmplia col·lecció de problemes de la selectivitat classificats segons aquesta temàtica.
          Mira-te'ls i així t'assegures una bona nota a l'examen.          `
        }
      }
      return text;
    },
    deApostrof() {
      const assig = this.$route.params.subject_name;
      const lletra = assig.charAt(0).toLowerCase()
      return lletra === 'a' || lletra === 'e' || lletra === 'i' || lletra === 'o' || lletra === 'u' || lletra === 'h' ? "d'" + assig : 'de ' + assig
    },
  },
  methods: {
    scrollInto(id) {
      const el = document.getElementById(id);

      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  },
  watch: {
    '$route.params': {
      immediate: true,
      async handler(val) {
        try {

          this.loading = true;
          const subject_name = this.$route.params.subject_name;
          this.subject = await Subject.build(subject_name, true);

          if (this.subject && this.subject.examsPerYear.length === 0)
            this.hasExams = false;
          else
            this.hasExams = true;


        } finally {
          this.loading = false;
          // this.refreshExams++;
        }

      }
    }
  }
}
</script>

<style>


.icon-color {
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.v-breadcrumbs__item {
  color: rgba(32, 32, 32, 0.7) !important;
  font-weight: 500;
}


.v-breadcrumbs__divider {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.v-tabs-slider-wrapper {
  height: 3px !important;
}

.v-tabs-slider {
  border-radius: 10px 10px 0 0 !important;
}

.subject-tab, .subject-tab .v-icon {
  color: rgba(37, 38, 94, .7) !important;
  caret-color: rgba(37, 38, 94, .7) !important;
}

.subject-tab:hover, .subject-tab:hover .v-icon {
  color: #466eb5 !important;
  caret-color: #466eb5 !important;
}

.nav-tab__node {
  align-items: center;
  text-transform: uppercase;
  padding: 20px;
  position: relative;
  margin-right: 32px;
  font-size: 16px;
  line-height: 24px;
}

.nav-tab__node--active {
  color: #0556f3 !important;
}

.pressed {
  display: table-cell;
  position: relative;
  box-shadow: 0 0.14rem 0.25rem rgba(0, 0, 0, .16);
  width: 100vw;
  z-index: 100;
}

.shadow-md {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

h3 {
  color: #333333;
  font-weight: 600 !important;
  font-family: Rubik, sans-serif !important;
}

#info2020 h3 {
  color: #607D8B;
  font-weight: 600 !important;
  font-family: Rubik, sans-serif !important;
}

h4 {
  font-size: 21px !important;
  color: #266FC7;
  font-weight: 500 !important;
  font-family: Rubik, sans-serif !important;
}

.rightPressed {
  display: table-cell;
  position: relative;
  box-shadow: 0 0.14rem 0.25rem rgba(0, 0, 0, .16);
  z-index: 100;
}

.leftPressed {
  display: table-cell;
  position: relative;
  box-shadow: 0 0.14rem 0.25rem rgba(0, 0, 0, .16);
  z-index: 100;
}

.tit {
  color: #333333;
  font-weight: 600;
  font-family: Rubik, sans-serif !important;
}

strong {
  font-weight: 600 !important;
}

.col-75 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 61%;
  flex: 0 0 61%;
  max-width: 61%;
}

.col-55 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 49%;
  flex: 0 0 49%;
}

.col-25 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 39%;
  flex: 0 0 39%;
  max-width: 39%;
}

.instagram {
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);

}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 90px;
}

.scale:hover {
  transform: scale(1.04);
}

.scale {
  transition: all 0.2s ease-in-out;
  transform: scale(1);
}

.three-lines-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits the number of lines to 3 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
