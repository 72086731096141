import BaseSubject, {IBaseSubject} from "../BaseSubject";
import SubjectBranch from "../SubjectBranch";
import axios from 'axios';

interface ISubjectBD {
  series: IExamsPerYear[];
  topics: IExamPerTopic[];
  subject_info: IBaseSubject
}


interface IExamsPerYear {
  year: string;
  june: number[];
  september: number[];
}

interface IExamPerTopic {
  counter: number;
  topic_name: string;
  topic_path: string;
}

export default class Subject {
  public examsPerYear: IExamsPerYear[];

  public examsPerTopic: IExamPerTopic[];

  public subject_info: BaseSubject;

  constructor(data: ISubjectBD) {
    this.examsPerYear = data.series;
    this.examsPerTopic = data.topics;
    this.subject_info = new BaseSubject(data.subject_info);
  }

  static async build(subject_name, reduced?) {
    const data = await Subject.fetchSubject(subject_name, reduced);
    return new Subject(data);
  }

  public hasExamsPerTopic(): boolean {
    return this.examsPerTopic && this.examsPerTopic.length > 0
  }

  public hasExamsPerYear(): boolean {
    return this.examsPerYear && this.examsPerYear.length > 0
  }

  private static async fetchSubject(subject_name: string, reduced?: Boolean): Promise<ISubjectBD | null> {
    try {
      const subject = subject_name.replace("/'", "\\'");
      let url = `/subject/${subject}/0`;
      if (reduced) url += '/true'

      const {data} = await axios.get(url)
      return data
    } catch (e) {
      console.error(e)
    }
  }

  public async fetchSubject(subject_name: string, offset?, problem_id?, reduced?: Boolean): Promise<ISubjectBD | null> {
    try {
      const subject = subject_name.replace("/'", "\\'");
      let url = `/subject/${subject}/${offset ? offset : 0}/${problem_id ? problem_id : null}`;
      if (reduced) url += '/true'

      const {data} = await axios.get(url)
      this.examsPerYear = data.series;
      this.examsPerTopic = data.topics;
      return data
    } catch (e) {
      console.error(e)
    }
  }

}
